import type { LinkProps } from 'labels/types'
import { Domains } from 'monitoring/constants/domains'
import { sendError } from 'monitoring/sendError/server/sendError'
// eslint-disable-next-line mng/no-next-link
import NextLink from 'next/link'
import type { ReactNode } from 'react'
import type { MasterData } from 'types/masterData'

interface LinkGlobalProps {
	children: ReactNode
	href: string
	masterData: MasterData
	id?: string
	'data-testid'?: string
	className?: string
	linkProps?: LinkProps
	onClick?: (event: React.MouseEvent) => void
	isLocalizedPath?: boolean
	logId?: string
}

export interface NextLinkProps extends LinkGlobalProps {
	prefetch?: boolean
	withLegacyLocale?: false
	skipTabKey?: boolean
	itemProp?: string
	isNextLink?: boolean
}

export interface AnchorLinkProps extends LinkGlobalProps {
	prefetch?: false
	withLegacyLocale?: boolean
	skipTabKey?: boolean
	itemProp?: string
	isNextLink?: boolean
}

function checkNoExternalHref(href: string, external: boolean, logId: string) {
	if (!external && href && !href.startsWith('/')) {
		throw sendError({
			message: `Link(${logId}) with "${href}" url must start with a "/" on internal links`,
			domain: Domains.Link,
		})
	}
}

function getLegacyLocale(
	isDefaultLanguage: boolean,
	languageISO: string,
	mangoISO: string
) {
	const lowerMangoISO = mangoISO.toLocaleLowerCase()
	return isDefaultLanguage ? lowerMangoISO : `${lowerMangoISO}-${languageISO}`
}

export function Link({
	id,
	'data-testid': dataTestId,
	children,
	href,
	masterData,
	withLegacyLocale,
	linkProps = {},
	className = '',
	onClick,
	prefetch = false,
	isLocalizedPath = false,
	skipTabKey,
	logId = '',
	isNextLink = false,
}: NextLinkProps | AnchorLinkProps) {
	const {
		country: { languageISO, countryISO, mangoISO, isDefaultLanguage },
	} = masterData

	const external = /^(https?:\/\/|mailto:|tel:)/.test(href)

	checkNoExternalHref(href, external, logId)

	const lowerCountryISO = countryISO.toLocaleLowerCase()
	const legacyLocale = getLegacyLocale(isDefaultLanguage, languageISO, mangoISO)

	if (external || withLegacyLocale) {
		return (
			<a
				id={id}
				className={className}
				href={external ? href : `/${legacyLocale}${href}`}
				onClick={onClick}
				data-testid={dataTestId}
				tabIndex={skipTabKey ? -1 : undefined}
				aria-hidden={skipTabKey ? true : undefined}
				{...linkProps}
			>
				{children}
			</a>
		)
	}

	const hrefLink = isLocalizedPath
		? href
		: `/${lowerCountryISO}/${languageISO}${href}`

	if (isNextLink) {
		return (
			<NextLink
				id={id}
				prefetch={prefetch}
				className={className}
				href={hrefLink}
				onClick={onClick}
				data-testid={dataTestId}
				{...linkProps}
			>
				{children}
			</NextLink>
		)
	}

	return (
		<a
			id={id}
			className={className}
			href={hrefLink}
			onClick={onClick}
			data-testid={dataTestId}
			{...linkProps}
		>
			{children}
		</a>
	)
}
