export enum Domains {
	CIAM = 'CIAM',
	Default = 'default',
	Home = 'home',
	Help = 'help',
	PDP = 'pdp',
	PRODUCT_CARD = 'productCard',
	PLP = 'plp',
	Labels = 'labels',
	logout = 'logout',
	FeatureFlag = 'featureFlag',
	CountryConfiguration = 'countryConfiguration',
	Link = 'link',
	Payments = 'payments',
	Legal = 'legal',
	Product = 'product',
	ProductCustomization = 'productCustomization',
	Search = 'search',
	AnalyticsReader = 'analyticsReader',
	Checkout = 'checkout',
	Footer = 'footer',
	GiftVoucher = 'giftVoucher',
	PromoBanner = 'promoBanner',
	Menu = 'menu',
	MyAddresses = 'myAddresses',
	MyDetails = 'MyDetails',
	BackOffice = 'backOffice',
	AtcForm = 'atcForm',
	MyPurchases = 'myPurchases',
	MyReturns = 'myReturns',
	Stores = 'stores',
	Editorials = 'editorials',
	Cart = 'cart',
}
