import { type BrandId, OutletBrand, ShopBrand } from 'types/brands'

export type BrandTranslationKeys = {
	[key in BrandId]: {
		labelKey: string
		urlKey: string
	}
}

export const brandTranslationKeys: BrandTranslationKeys = {
	[ShopBrand.she]: {
		labelKey: 'line.woman.she.text',
		urlKey: 'shop.url.home.she',
	},
	[ShopBrand.he]: { labelKey: 'line.man.he.text', urlKey: 'shop.url.home.he' },
	[ShopBrand.kids]: {
		labelKey: 'line.kids.text',
		urlKey: 'shop.url.home.kids',
	},
	[ShopBrand.newBorn]: {
		labelKey: 'line.kids.newBorn.text',
		urlKey: 'shop.url.newborn',
	},
	[ShopBrand.babyNina]: {
		labelKey: 'line.kids.babyNina.text',
		urlKey: 'shop.url.kidsE',
	},
	[ShopBrand.babyNino]: {
		labelKey: 'line.kids.babyNino.text',
		urlKey: 'shop.url.kidsD',
	},
	[ShopBrand.nina]: {
		labelKey: 'line.kids.nina.text',
		urlKey: 'shop.url.kidsA',
	},
	[ShopBrand.nino]: {
		labelKey: 'line.kids.nino.text',
		urlKey: 'shop.url.kidsO',
	},
	[ShopBrand.teen]: {
		labelKey: 'line.teen.text',
		urlKey: 'shop.url.home.teen',
	},
	[ShopBrand.teenA]: {
		labelKey: 'line.teen.teenA.text',
		urlKey: 'shop.url.teenA',
	},
	[ShopBrand.teenO]: {
		labelKey: 'line.teen.teenO.text',
		urlKey: 'shop.url.teenO',
	},
	[ShopBrand.home]: {
		labelKey: 'line.home.text',
		urlKey: 'shop.url.home.home',
	},
	[OutletBrand.outlet]: {
		labelKey: 'line.woman.outlet.text',
		urlKey: 'shop.url.home.outlet',
	},
	[OutletBrand.outletH]: {
		labelKey: 'line.man.outletH.text',
		urlKey: 'shop.url.home.outletH',
	},
	[OutletBrand.outletK]: {
		labelKey: 'line.outletK.text',
		urlKey: 'shop.url.kidsK',
	},
	[OutletBrand.outletA]: {
		labelKey: 'line.kids.girl.outletA.text',
		urlKey: 'shop.url.kidsA',
	},
	[OutletBrand.outletO]: {
		labelKey: 'line.kids.boy.outletO.text',
		urlKey: 'shop.url.kidsO',
	},
	[OutletBrand.outletBA]: {
		labelKey: 'line.kids.babyGirl.outletBA.text',
		urlKey: 'shop.url.kidsE',
	},
	[OutletBrand.outletBO]: {
		labelKey: 'line.kids.babyBoy.outletBO.text',
		urlKey: 'shop.url.kidsD',
	},
	[OutletBrand.outletNB]: {
		labelKey: 'line.kids.newBorn.outletNB.text',
		urlKey: 'shop.url.kidsNB',
	},
	[OutletBrand.outletT]: {
		labelKey: 'form.lines.outletT.checkbox',
		urlKey: 'shop.url.teenT',
	},
	[OutletBrand.outletQ]: {
		labelKey: 'line.teen.outletQ.text',
		urlKey: 'shop.url.teenQ',
	},
	[OutletBrand.outletP]: {
		labelKey: 'line.teen.outletP.text',
		urlKey: 'shop.url.teenP',
	},
	[OutletBrand.outletC]: {
		labelKey: 'line.outletC.text',
		urlKey: 'shop.url.homeC',
	},
}
