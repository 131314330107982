import type { Brand as BrandCountryConfig } from 'country-configuration/types/Country.type'
import { Domains } from 'monitoring/constants/domains'
import { Types } from 'monitoring/constants/types'
import { sendLog } from 'monitoring/sendLog/server/sendLog'
import type { Brand } from 'types/brands'

import { brandTranslationKeys } from '../../constants/brands'

export const mapLabelKeysToBrands = (brands: BrandCountryConfig[]): Brand[] =>
	brands.map((brand: BrandCountryConfig) => {
		const brandKeys = brandTranslationKeys[brand.id]

		if (!brandKeys) {
			sendLog({
				domain: Domains.CountryConfiguration,
				type: Types.Error,
				message: `Brand not found in translation keys map: [brandTranslationKeys]`,
				custom: `brand id: ${brand.id}`,
			})
		}

		const { labelKey, urlKey } = brandKeys

		const parsedBrand: Brand = {
			id: brand.id,
			labelKey,
			urlKey,
		}

		if (brand.subBrands) {
			parsedBrand.subBrands = mapLabelKeysToBrands(brand.subBrands)
		}

		return parsedBrand
	})
